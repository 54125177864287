import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NIVELES_MAX } from 'src/app/common/constantes';
import { Configuracion } from 'src/app/model/configuracion';
import { ConfiguracionGeneralService } from 'src/app/services/configuracion-general.service';
import { IntegracionesService } from 'src/app/services/integraciones.service';
import { MessagesService } from 'src/app/services/message.service';
import { TablaGenericaComponent } from '../../common/tabla-generica/tabla-generica.component';
import { concatMap, of } from 'rxjs';
import { DialogWarningComponent } from '../../common/dialog-warning/dialogo-warning.component';

@Component({
  selector: 'app-configuracion-general',
  templateUrl: './configuracion-general.component.html',
  styleUrl: './configuracion-general.component.scss'
})
export class ConfiguracionGeneralComponent {

  fechasCierreFacturas: Date[] | undefined;
  fechasCierreNominas: Date[] | undefined;

  ref: DynamicDialogRef | undefined;

  configuracion: Configuracion = new Configuracion();

  constructor(public configuracionGeneralService: ConfiguracionGeneralService, 
              public integacionesService: IntegracionesService,
              public messageService: MessagesService,
              public dialogService: DialogService) {

  }

  ngOnInit(): void {
    this.configuracionGeneralService.obtenerConfiguracion([NIVELES_MAX]).subscribe(conf => {
      this.configuracion.setConfiguracion(conf);
    });
    this.configuracionGeneralService.obtenerContratos().subscribe(a => this.configuracion.tiposContrato = a);
  }

  guardarNiveles() {
    this.configuracionGeneralService.update([{key: 'NIVELES_MAX',
                                            value : this.configuracion.nivelesMax}]).subscribe(res =>{
      if (res) {
        this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Modificado correctamente' });
      } else {
        this.messageService.setMensaje({ severity: 'error', summary: 'Error', detail: 'Error al modificar el parámetro' });
      }
    });
  }

  volcarFacturas() {
    this.integacionesService.obtenerFacturas(this.fechasCierreFacturas!).subscribe(res => {
      this.fechasCierreFacturas = undefined;
      if (res) {
        this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Volcado correctamente' });
      } else {
        this.messageService.setMensaje({ severity: 'warn', summary: 'Aviso', detail: 'No se recuperaron ninguna factura para el intervalo seleccionado' });
      }
});
  }

  volcarNominas() {
    this.configuracionGeneralService.updateTiposContratos(this.configuracion.tiposContrato)
        .pipe(concatMap(() => this.integacionesService.obtenerNominas(this.fechasCierreNominas!)))
        .subscribe(res => {
          this.fechasCierreNominas = undefined;
          if (res) {
            this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Volcado correctamente' });
          } else {
            this.messageService.setMensaje({ severity: 'warn', summary: 'Aviso', detail: 'No se recuperaron ninguna nómina para el intervalo seleccionado' });
          }
    });
  }

  verHistorico() {
    this.configuracionGeneralService.obtenerHistorico().subscribe( (historico: any) => {
      if (historico.length == 0) {
        this.ref = this.dialogService.open(DialogWarningComponent, {
          header: 'Sin datos',
          data: { texto: 'No existen datos todavía' }
        });
      } else {
        this.ref = this.dialogService.open(TablaGenericaComponent, {
          header: 'Histórico',
          width: '500px',
          contentStyle: { overflow: 'auto' },
          data: {
              data: of(historico),
              tienePaginacion: true,
              nombreFichero: `Coste_historico`,
              columns: [
                  {
                    name : "fecha",
                    cabecera: "Fecha"
                  },
                  {
                    name : "costeContFijo",
                    cabecera: "Coste contrato fijo"
                  },
                  {
                    name : "costeContOtros",
                    cabecera: "Coste contrato otros"
                  }
                ],
              id: "id"
          }
        });
      }
    });
  }
}
