<div class="flex flex-row gap-4">
    <p-card class="flex flex-row w-full h-12rem mt-3">
        <ng-template pTemplate="header">
            <div class="flex flex-row align-items-center p-2">
                <span class="font-bold underline xl mt-2 ml-2">Cierre de mes - Facturas</span>
            </div>
        </ng-template>
        
        <div class="flex flex-row align-items-center">
            <span class="p-float-label w-9 p-fluid">
                <p-calendar 
                    [(ngModel)]="fechasCierreFacturas" 
                    selectionMode="range"
                    [showIcon]="true"/>
                <label for="mes">Fecha desde - Fecha hasta</label>
            </span>
        </div>

        <div class="flex flex-row mt-2 justify-content-end">
            <p-button label="Volcar datos" [disabled]="!fechasCierreFacturas || !fechasCierreFacturas![1]" (onClick)="volcarFacturas()"/>
        </div>
    </p-card>

    <p-card class="flex flex-row w-full mt-3">
        <ng-template pTemplate="header">
            <div class="flex flex-row gap-2 align-items-center p-2">
                <span class="font-bold underline xl mt-2 ml-2">Cierre de mes - Nóminas</span>
            </div>
        </ng-template>

        <div class="flex flex-rowalign-items-center mb-5">
            <span class="p-float-label w-10 p-fluid">
                <p-calendar 
                    [(ngModel)]="fechasCierreNominas" 
                    selectionMode="range"
                    [showIcon]="true"/>
                <label for="mes">Fecha desde - Fecha hasta</label>
            </span>
        </div>

        <div class="flex flex-row align-items-center mb-5" *ngFor="let tipo of configuracion.tiposContrato; let i = index" >
            <span class="p-float-label w-10 p-fluid">
                <p-inputNumber max="100" min="1" suffix=" %" mode="decimal" [minFractionDigits]="2" [(ngModel)]="configuracion.tiposContrato[i].porcentaje"></p-inputNumber>
                <label for="descripcion">SS(Coste empresa): {{tipo.nombre}} %</label>
            </span>
        </div>

        <div class="flex flex-row mt-2 justify-content-end gap-2">
            <p-button label="Volcar datos" [disabled]="!fechasCierreNominas || !fechasCierreNominas![1]" (onClick)="volcarNominas()"/>
            <p-button label="Histórico" icon="pi pi-history" (onClick)="verHistorico()"/>
        </div>
    </p-card>

    <p-card class="flex flex-row w-full h-12rem mt-3">
        <ng-template pTemplate="header">
            <div class="flex flex-row gap-2 align-items-center p-2">
                <span class="font-bold underline xl mt-2 ml-2">Niveles estructura económica</span>
            </div>
        </ng-template>

        <div class="flex flex-row gap-2 align-items-center">
            <span class="p-float-label">
                <input type="text" [(ngModel)]="configuracion.nivelesMax" pInputText />
                <label for="descripcion">Niveles</label>
            </span>
        </div>

        <div class="flex flex-row mt-2 justify-content-end">
            <p-button label="Guardar" icon="pi pi-save" (onClick)="guardarNiveles()"/>
        </div>
    </p-card>
</div>