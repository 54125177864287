import { Component, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrl: './dialog-confirm.component.scss'
})
export class DialogConfirmComponent {
  @Input() header!: string;
  @Input() width: string = '500px';

  constructor(public config: DynamicDialogConfig, private confirmationService: ConfirmationService,
    private ref: DynamicDialogRef
  ) {
  }

  ngOnInit(): void {
    this.confirmationService.confirm({
      header: this.config.header,
      message: this.config.data.texto
  });
  }

  aceptar(){
    this.ref.close(true);
  }

  cancelar() {
    this.ref.close(false);
  }
 }
