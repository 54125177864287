<div>
    <!--  <h2>{{ isEdit ? 'Edit Beneficiario' : 'New Beneficiario' }}</h2> -->
    <form [formGroup]="beneficiarioForm" class="flex flex-column gap-3 mt-5" (ngSubmit)="save()">
        <div class="flex flex-row gap-5 p-fluid">
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nif" placeholder="NIF" />
                    <label for="nif">NIF *</label>
                    <div>
                        <small *ngIf="beneficiarioForm.get('nif')?.invalid && beneficiarioForm.get('nif')?.touched"
                            class="p-error">
                            NIF no es válido
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nombre" placeholder="Nombre" />
                    <label for="nombre">Nombre *</label>
                    <div>
                        <small
                            *ngIf="beneficiarioForm.get('nombre')?.invalid && beneficiarioForm.get('nombre')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
        </div>
        <div class="flex pt-4 justify-content-end">
            <p-button label="Cancelar" class="mr-2" severity="secondary" (onClick)="cancelar()" />
            <p-button *ngIf="this.isEdit" icon="pi pi-trash"  label="Borrar" (click)="borrar(this.idBeneficiario)" severity="danger" class="mr-2"></p-button>
            <p-button type="submit" icon="pi pi-save" label="Guardar" severity="success" [disabled]="beneficiarioForm.invalid" />
        </div>
    </form>
</div>