import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SessionService } from './services/session-service';
import { MessagesService } from './services/message.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Mensaje } from './model/mensaje';
import { MigaService } from './services/miga.service';
import { migas } from './model/miga';
import { filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Proyecto Públicos';

  constructor(private session: SessionService,
    private router: Router, private messageService: MessagesService, private msgPrimeService: MessageService,
      public migaService: MigaService, private route: ActivatedRoute, private translateService: TranslateService,
      private primengConfig: PrimeNGConfig) {
      
    this.messageService.getMensaje.subscribe((msg: Mensaje | null) => this.msgPrimeService.add(msg!));

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      
      // Acceder a los datos de la ruta actual
      let routeWithData = this.route;
      while (routeWithData.firstChild) {
        routeWithData = routeWithData.firstChild;
      }
      this.migaService.setMiga(migas[routeWithData.snapshot.data["urlMiga"]]);
    });
    
    this.translateService.use('es');
    this.translateService.get('primeng').subscribe((res) => this.primengConfig.setTranslation(res))
  }
}
