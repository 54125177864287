export class Enlace {
    texto!: string;
    enlace?: string;
}

export class Elemento {
    texto?: string;
    class!: string;
    icon?: string;
}

export class Miga {
    public nombre?: string;
    public subtitulo?: Elemento; 
    public nav?: Enlace[];

    constructor(fields: Partial<Miga> = {}) {
        Object.assign(this, fields);
    }
}

// añadir aqui las URL que tengan una miga asociada
export const migas: { [key: string]: Miga } = 
    {
        proyectosListado: {
            nombre: "Proyectos", 
            nav: [
                {
                    texto: "Proyectos", 
                    enlace: "proyectos/listado"}, 
                {
                    texto: "Listado"
                }
            ]
        },
        proyectosGestion: {
            nombre: "Proyectos", 
            nav: [
                {
                    texto: "Proyectos", 
                    enlace: "proyectos/listado"}, 
                {
                    texto: "Gestion"
                }
            ]
        },
        proyectosResumen: {
            nav: [ 
                {
                    texto: "Proyectos", 
                    enlace: "proyectos/listado"}, 
                {
                    texto: "Resumen", 
                }
            ]
        },
        proyectosNuevo: {
            nombre: "Nuevo Proyecto", 
        },
        proyectosEdicion: {
            nombre: "Edicion Proyecto", 
            nav: [ 
                {
                    texto: "Proyectos", 
                    enlace: "proyectos/listado"}, 
                {
                    texto: "Resumen"
                },
                {
                    texto: "Edición"
                }
            ]
        },
        home: {
            nombre: "Inicio"
        },
        imputaciones: {
            nombre: "Imputaciones",
            nav: [
                {
                    texto: "Imputaciones"
                }, 
                {
                    texto: "Calendario"
                }
            ]
        },
        imputacionesTabla: {
            nombre: "Imputaciones",
            nav: [
                {
                    texto: "Imputaciones"
                }, 
                {
                    texto: "Tabla"
                }
            ]
        },
        imputacionesCalendario: {
            nombre: "Imputaciones",
            nav: [
                {
                    texto: "Imputaciones"
                }, 
                {
                    texto: "Calendario"
                }
            ]
        },
        /* configuracion: {
            nombre: "Configuración"
        }, */
        configuracionBeneficiarios: {
            nombre: "Beneficiarios", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/beneficiarios"}, 
                {
                    texto: "Beneficiarios"
                }
            ]
        },
        beneficiarioNuevo: {
            nombre: "Nuevo beneficiario", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/beneficiarios"}, 
                {
                    texto: "Beneficiarios", 
                    enlace: "configuracion/beneficiarios"}, 
                {
                    texto: "Nuevo beneficiario"
                }
            ]
        },
        beneficiarioEdicion: {
            nombre: "Editar beneficiario",
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/beneficiarios"}, 
                {
                    texto: "Beneficiarios", 
                    enlace: "configuracion/beneficiarios"}, 
                {
                    texto: "Editar beneficiario"
                }
            ] 
        },
        configuracionTareas: {
            nombre: "Tareas", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/tareas"}, 
                {
                    texto: "Tareas"
                }
            ]
        },
        tareaNueva: {
            nombre: "Nueva tarea", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/tareas"}, 
                {
                    texto: "Tareas", 
                    enlace: "configuracion/tareas"}, 
                {
                    texto: "Nueva tarea"
                }
            ]
        },
        tareaEdicion: {
            nombre: "Editar tarea", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/tareas"},
                {
                    texto: "Tareas", 
                    enlace: "configuracion/tareas"}, 
                {
                    texto: "Editar tarea"
                }
            ]
        },
        configuracionTiposDocumento: {
            nombre: "Tipos de documento", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/tipos-documento"}, 
                {
                    texto: "Tipos de documento"
                }
            ]
        },
        tipoDocumentoNuevo: {
            nombre: "Nuevo tipo de documento", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/tipos-documento"}, 
                {
                    texto: "Tipos de documento", 
                    enlace: "configuracion/tipos-documento"}, 
                {
                    texto: "Nuevo tipo de documento"
                }
            ]
        },
        tipoDocumentoEdicion: {
            nombre: "Editar tipo de documento",
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/tipos-documento"}, 
                {
                    texto: "Tipos de documento", 
                    enlace: "configuracion/tipos-documento"}, 
                {
                    texto: "Editar tipo de documento"
                }
            ] 
        },
        configuracionConceptosSalariales: {
            nombre: "Conceptos salariales", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/conceptos-salariales"}, 
                {
                    texto: "Conceptos salariales"
                }
            ]
        },
        conceptoSalarialNuevo: {
            nombre: "Nuevo concepto salarial", 
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/conceptos-salariales"}, 
                {
                    texto: "Conceptos salariales", 
                    enlace: "configuracion/conceptos-salariales"}, 
                {
                    texto: "Nuevo concepto salarial"
                }
            ]
        },
        conceptoSalarialEdicion: {
            nombre: "Editar concepto salarial",
            nav: [
                {
                    texto: "Configuración", 
                    enlace: "configuracion/conceptos-salariales"}, 
                {
                    texto: "Conceptos salariales", 
                    enlace: "configuracion/conceptos-salariales"}, 
                {
                    texto: "Editar concepto salarial"
                }
            ] 
        },
    }
