<!-- <div class="card"> -->
    <p-carousel *ngIf="this.horasPorProyecto.length > 0"
        [value]="this.horasPorProyecto" 
        [numVisible]="5" 
        [numScroll]="1" 
        [responsiveOptions]="responsiveOptions">
            <ng-template let-proyecto pTemplate="item">
                    <div class="mr-2 w-fit"> 
                        <span class="flex gap-2 mb-1"><i class="pi pi-circle-fill" [ngStyle]="{'color': proyecto!.color}"></i>{{proyecto.nombre}}</span>
                        <div class="relative mx-auto flex flex-row border-round-lg gap-2 border-1  p-2 text-center justify-content-center" id="horas">
                            <div class="flex flex-row pr-2 border-right-2">
                                <i class="pi iconProGestion"></i>
                                <span class="flex flex-column align-items-center">
                                    <span>Horas totales</span>
                                    <span class="font-bold">{{proyecto.horasTotales}}</span>
                                </span>
                                
                            </div>
                            <div class="flex flex-row pr-2 border-right-2">
                                <i class="pi iconHorasImputadas"></i>
                                <span class="flex flex-column align-items-center">
                                    <span>Horas imputadas</span>
                                    <span class="font-bold">{{proyecto.horasImputadas}}</span>
                                </span>
                            </div>
                            <div class="flex flex-row">
                                <i class="pi iconHorasRestantes"></i>
                                <span class="flex flex-column align-items-center">
                                    <span>Horas restantes</span>
                                    <span class="font-bold">{{proyecto.horasRestantes}}</span>
                                </span>
                            </div>
                            
                        </div>
                    </div>
            </ng-template>
    </p-carousel>
<!-- </div> -->