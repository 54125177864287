<form [formGroup]="formulario">
    <ng-container *ngFor="let item of this.config.data">
        <div *ngFor="let key of objectKeys(item)" class="flex align-items-center gap-3 mb-3">
            <label [for]="key" class="font-semibold w-6rem">{{ item[key] }}</label>
            <input pInputText [formControlName]="key" class="flex-auto" autocomplete="off" />
        </div>
    </ng-container>
    
</form>

<div class="flex justify-content-end gap-2">
    <p-button label="Cancelar" severity="secondary" (onClick)="retornar(false)" />
    <p-button label="Aceptar" (onClick)="retornar(true)" />
</div>
