import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { CommonModule } from "@angular/common";
import { AuthInterceptor, HttpErrorInterceptor } from "../../interceptors/http-interceptors";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { PrimeNgModule } from "./primeng.core.module";
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";
import { LoginComponent } from "../home/login/login.component";
import { SpinnerComponent } from "../common/spinner/spinner.component";
import { SpinnerService } from "src/app/services/spinner.service";

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        AppRoutingModule,
        CommonModule,
        PrimeNgModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        SpinnerService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
       // { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true }
    ],
    bootstrap: [],
    exports: []
})

export class ProyectosPublicosCoreModule {

    constructor() {

    }
  
  }