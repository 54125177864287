import { BehaviorSubject, Observable, of } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SessionService } from 'src/app/services/session-service';
import { TableColum } from 'src/app/model/table-column';
import { LocalStorageService } from 'src/app/common/local-storage';
import { ROLES_SIN_PROYECTO, Role } from 'src/app/model/session';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Component, ViewChild } from '@angular/core';
import { TablaGenericaComponent } from 'src/app/ui/common/tabla-generica/tabla-generica.component';
import { DialogConfirmComponent } from 'src/app/ui/common/dialog-confirm/dialog-confirm.component';
import { Roles } from 'src/app/model/roles';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';
import { TipoDocumento } from 'src/app/model/tipoDocumento';
import { TiposDocumentoService } from 'src/app/services/tipos-documento.service';
import { ConceptoSalarial } from 'src/app/model/conceptoSalarial';
import { ConceptosSalarialesService } from 'src/app/services/conceptos-salariales.service';


@Component({
  selector: 'app-listado-conceptos-salariales',
  templateUrl: './listado-conceptos-salariales.component.html',
  styleUrl: './listado-conceptos-salariales.component.scss'
})
export class ListadoConceptosSalarialesComponent {

  ref: DynamicDialogRef | undefined;

  conceptos: Observable<ConceptoSalarial[]> | undefined;

  edicion = (item: ConceptoSalarial) => {
    this.localStorage.set('conceptoSalarialEdicion', item);
    this.router.navigateByUrl(`/configuracion/edicion-concepto-salarial/${item.id}`);
  };

  borrar = (item: ConceptoSalarial) => {

    /* if (item.tieneRegistros) {
      this.abrirDialogo();

    } else { */
      this.ref = this.dialogService.open(DialogConfirmComponent, {
        header: 'Borrado',
        data: { texto: '¿Está seguro de borrar el concepto salarial?' }
      });

      this.ref.onClose.subscribe((data: any) => {
        if (data) {
          this.conceptosSalarialesService.delete(item.id).subscribe({
            next: res => {
              this.dt.borrarRegistro(item);
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Concepto salarial borrado con éxito', life: 3000 });
            },
            error: error => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al borrar el concepto salarial', life: 3000 });
            }
          });
        }

      });
    /* } */
  }

  columns: TableColum[] = [];

  @ViewChild('tabla') dt!: TablaGenericaComponent<ConceptoSalarial>;

  constructor(private conceptosSalarialesService: ConceptosSalarialesService<ConceptoSalarial>, private router: Router,
    public dialogService: DialogService, public messageService: MessageService, private sessionService: SessionService,
    public localStorage: LocalStorageService
  ) {

    this.conceptos = this.conceptosSalarialesService.list();

    this.columns = [
      {
        name: "nombre",
        cabecera: "Nombre",
        ancho: 'w-min'
      },
      {
        name: "descripcion",
        cabecera: "Descripcion",
        ancho: 'w-min'
      },
      {
        name: "acciones",
        cabecera: "Acciones",
        filter: false,
        ancho: 'w-min',
        sortable: false,
        exportable: false,
        // acciones listado
        acciones: [
          {
            function: this.edicion,
            icon: 'pi iconProConfig',
            tooltip: "Edición",
            condicion: { funcion: this.estadoPermitido, parametros: { roles: [Role.ADMIN] } }
          },
          {
            function: this.borrar,
            icon: 'pi pi-trash',
            tooltip: "Borrar",
            condicion: { funcion: this.estadoPermitido, parametros: { roles: [Role.ADMIN] } }
          }
        ]
      }
    ];
  }

  nuevoConcepto() {
    this.localStorage.remove('conceptoSalarialEdicion');
    this.router.navigateByUrl('/configuracion/nuevo-concepto-salarial');
  }

  puedeCrear() {
    return this.sessionService.role.find(rol => rol.nombre == Role.ADMIN);
  }

  /* abrirDialogo() {
    setTimeout(() => {
      this.ref = this.dialogService.open(DialogWarningComponent, {
        header: 'Borrado incorrecto',
        width: '500px',
        data: { texto: 'No se pudo borrar el tipo de documento porque existen ficheros asociados.' }
      });

      this.ref.onClose.subscribe((data: any) => {
        if (data) {

        }

      });
    }, 100); // 3000 milisegundos = 3 segundos
  } */

  estadoPermitido = (item: ConceptoSalarial, parametros: any) => {
    let flagRol;
    if (parametros.roles) {
      flagRol = parametros.roles?.find((a: string) =>
        this.sessionService.role.find((b: Roles) => b.nombre == a && (b.proyectos?.find(p => p.id == item.id) || ROLES_SIN_PROYECTO.find(rol => rol == b.nombre))))
    }
    return flagRol;
  }

}
