<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
    <div class="flex flex-column align-items-center p-5 surface-overlay border-round" [style]="'width:' + message.width">
        <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8 dangerDialog">
            <i class="pi pi-question text-5xl"></i>
        </div>
        <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex align-items-center gap-2 mt-4">
            <button pButton label="Si" (click)="aceptar()" class="w-8rem p-button-danger"></button>
            <button pButton label="No" (click)="cancelar()" class="p-button-outlined w-8rem p-button-danger"></button>
        </div>
    </div>
    </ng-template>
</p-confirmDialog>


