import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../home/login/login.component';
import { AuthGuardService } from '../../services/authguard-service';
import { HomeComponent } from '../home/home/home.component';
import { URL_HOME_REL } from 'src/app/common/constantes';
import { Role } from 'src/app/model/session';
import { RoleGuard } from 'src/app/services/role-service';
import { ValidandoComponent } from '../common/validando/validando.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'validando',
    component: ValidandoComponent
  }
  ,
  {
    path: '', canActivate: [AuthGuardService], component: HomeComponent, children: [
      {
        path: '',
        redirectTo: URL_HOME_REL,
        pathMatch: 'full'
      },
      // Lazyload de los otros modulos
      {
        path: URL_HOME_REL,
        loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'imputacion',
        loadChildren: () => import('../imputacion/imputacion.module').then((m) => m.ImputacionModule),
      },
      {
        path: 'proyectos',
        loadChildren: () => import('../proyectos/proyectos.module').then((m) => m.ProyectosModule),
      },
      {
        path: 'configuracion', canActivate: [RoleGuard], data: { roles: [Role.ADMIN, Role.RRHH, Role.DIRECTOR, Role.GESTOR] },
        loadChildren: () => import('../configuracion/configuracion.module').then((m) => m.ConfiguracionModule),
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
