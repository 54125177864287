import { EntityBase } from "./entityBase";
import { HorasMes } from "./horas-mes";
import { Roles } from "./roles";

export class Usuario extends EntityBase{
    public numExpediente!: string | null;
    public password?: string;
    public fullName?: string;
    public role?: Roles[];
    public departamento?: EntityBase;
    public esAdmin?: boolean = false;
    public delegante?: boolean = false;
    public horasMes?: HorasMes[];
    public totalHorasPlanificadas?: number;
    public totalCosteHorasPlanificadas?: number;
    public totalCosteHorasImputadas?: number;

    constructor(fields: Partial<Usuario> = {}) {
        super();
        Object.assign(this, fields);
    }
}