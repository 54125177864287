import { Observable } from "rxjs";

interface Condicion {
    funcion: Function;
    parametros?: any;
}

export class Accion {
    function!: Function;
    icon: string = "";
    tooltip: string = "";
    condicion?: Condicion;
}

export class Editable {
    type: string = "input";
    typeData?: string = "text";
    service?: Observable<any>;
    data?: any[];
    dataFunction?: Function;
    placeHolder?: string =  "";
    campoMostrado?: string;
    obtenerObj?: Function;
    editableFun?: (...args: any[]) => any;
}

export class TableColum {
    //se fijan los valores por defecto de las columnas: se buscan en el filtro y ordenable
    name : string = "";
    cabecera: string = "";
    filter?: boolean = true;
    ancho?: string = "w-min";
    tipo?: string = "";
    sortable?: boolean = true;
    acciones?: Accion[] = [];
    format?: Function;
    class?: Function;
    innerHTML?: boolean = false;
    frozen?: boolean = false;
    style?: Function;
    editable?: Editable;
    exportable?: boolean = true;
    click?: Function;
    tooltip?: Function;

    constructor(fields: Partial<TableColum> = {}) {
        Object.assign(this, fields);
    }
}