import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Mensaje } from '../model/mensaje';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private _mensaje = new BehaviorSubject<Mensaje | null>(null);

  get getMensaje(): Observable<Mensaje | null> {
    return this._mensaje.asObservable();
  }

  setMensaje(mensaje: Mensaje) {
    if((mensaje.detail == "Ya existe una tarea con este nombre.") || (mensaje.detail == "Ya existe un beneficiario con este NIF.") ||
     (mensaje.detail == "Ya existe un tipo de documento con este nombre.") || (mensaje.detail == "Ya existe un concepto salarial con este nombre.")){
      mensaje.severity = "warn";
      mensaje.summary = "Atención";
    }
    this._mensaje.next(mensaje);
  }
}