import { EntityBase } from "./entityBase";
import { Imputacion } from "./imputacion";
import { Proyecto } from "./proyecto";

export class Dia {
    imputaciones?: Imputacion[];
    fecha!: Date;
    esFestivo: boolean = false;

    constructor(fields: Partial<Dia> = {}) {
        Object.assign(this, fields);
    }
}

export class DiaAgrupado {
    proyectos?: Proyecto[];
    fecha!: Date;
    esFestivo: boolean = false;
    horas?: number;
    horasOtrosProyectos?: number;
    id!: number;
    estado!: EntityBase;

    constructor(fields: Partial<DiaAgrupado> = {}) {
        Object.assign(this, fields);
    }
}