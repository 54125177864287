import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { meses } from 'src/app/common/constantes';
import { LocalStorageService } from 'src/app/common/local-storage';
import { TableColum } from 'src/app/model/table-column';
import { ExcelService } from 'src/app/services/excel.service';
import { ProyectoGestionServiceService } from 'src/app/services/proyecto-gestion-service.service';

@Component({
  selector: 'app-tabla-generica',
  templateUrl: './tabla-generica.component.html',
  styleUrl: './tabla-generica.component.scss'
})
export class TablaGenericaComponent<T> {

  // variable con las columnas formateadas
  public _columns: TableColum[] = [];
  public _selectedColumns: TableColum[] = [];
  public _ocultablesColumns: TableColum[] = [];
  @Input("columasOcultable")
  public set columasOcultable(newData: any[]) {
    // se transforma el array de columnas al formato por defecto
    newData.forEach(a => this._ocultablesColumns?.push(new TableColum(a)));
  }

  @Input() data: Observable<T> | undefined;
  @Input("columns")
  public set columns(newData: any[]) {
    // se transforma el array de columnas al formato por defecto
    newData.forEach(a => this._columns?.push(new TableColum(a)));
  }
  @Input() id: string = "";
  @Input() mensaje: string = "";
  @Input() numeroFilas: number = 10;
  @Input() tienePaginacion: boolean = true;
  @Input() sizeScroll: string = "2000px";
  @Input() nombreFichero;
  @Input() mostrarMensaje = true;
  @Input() mostrarBarraTareas = true;
  @Input() mostrarExcel = true;
  @Input() mostrarBuscador = true;
  @Input() tooltipDepartamento = false;

  @Input() valorOculto: any;
  @Output() checkboxChange = new EventEmitter<{valor: any, event: any}>();

  filters: string[] | undefined;
  results: any;

  @ViewChild('tabla') dt!: Table;

  constructor(private excelService: ExcelService, @Optional() private configDialog: DynamicDialogConfig,
    @Optional() private ref: DynamicDialogRef, private cdref: ChangeDetectorRef, private proyectoGestionService: ProyectoGestionServiceService, public router: Router, public localStorage: LocalStorageService) {

    // en el caso de fuera una carga en un diálogo
    if (this.configDialog) {
      this.data = this.configDialog.data.data;
      this.columns = this.configDialog.data.columns;
      this.id = this.configDialog.data.id;
      this.tienePaginacion = this.configDialog.data.tienePaginacion;
      this.nombreFichero = this.configDialog.data.nombreFichero;
    }
  }

  getCol() {
    const colElim = this._ocultablesColumns.filter(col => !this._selectedColumns.find(col2 => col2.name == col.name))
    return this._columns.filter(col => !colElim.find(col2 => col2.name == col.name));
  }

  get selectedColumns(): TableColum[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: TableColum[]) {
    //restore original order
    this._selectedColumns = this._columns.filter((col) => val.includes(col));
  }

  soloOcultables() {
    return this._columns.filter(col => this._ocultablesColumns?.find(oc => oc.name == col.name));
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.nombreFichero = this.nombreFichero ? this.nombreFichero : this.mensaje;
    this.data!.subscribe((results: T) => this.results = results);
    this.filters = this._columns.filter((e: any) => e.filter).map((f: any) => f.name);
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  limpiarFiltro(input: any) {
    this.dt.clear();
    input.value = "";
  }

  obtenerValor(item: T, column: TableColum) {
    //si la columna tiene formato propio, se invoca a su función
    if (column.format) {
      return column.format(item, column);
    } else if (column.name != 'acciones') {
      try {
        let valor = eval('item.' + column.name);
        if (typeof valor == 'number') {
          // para pintar con puntos de miles
          return valor.toLocaleString('de');
        }
        return valor;
      } catch (error) {

      }

    }
  }

  exportExcel(): void {
    //formateo de los datos para exportación
    let dataSelect = Object.keys(this.dt.filters).length === 0 ?  this.dt.value : this.dt.filteredValue;
    if(dataSelect![0].hasOwnProperty('esSeleccionado')){
      dataSelect = dataSelect!.filter((item:any) => item.esSeleccionado === true)
    }
    let data = dataSelect!.map((item: T) => {
      const newItem: any = {};
      // se fija la cabecera y el valor de los campos exportables
      this._columns.filter(col => col.exportable).forEach(col => {
        newItem[col.cabecera] = this.obtenerValor(item, col);
      }
      );
      return newItem;
    });

    if (this.valorOculto){
      this.valorOculto.forEach((valor: any) => {
        data = data.map(d => ({ ...valor, ...d}));
      });
    }
    this.excelService.exportAsExcelFile(data, this.nombreFichero);
  }

  verificarMes(column: any) {
    if (meses.some(mes => mes.cabecera === column.cabecera)) {
      return true;
    } else {
      return false;
    }
  }

  obtenerEstilo(condicion: any, item: T) {
    if (condicion?.parametros) {
      return condicion!.funcion(item, condicion!.parametros) ? '' : 'display:none';
    } else {
      return condicion && (condicion!.funcion(item) ? '' : 'display:none');
    }
  }

  public borrarRegistro(registro: any) {
    this.results = this.results.filter((reg: any) => reg.id != registro.id);
  }
  mostrar() {
    return this.results && this.results.filter((res: any) => !res?.rowStyle?.includes("totales")).length > 0
  }

  onCheckboxChange(valor: any, event: any): void {
    this.checkboxChange.emit({ valor, event });
  }
}