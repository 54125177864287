import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { Usuario } from '../model/usuario';
import { CRUDServiceService } from './crudservice.service';
import { URL_HOME } from '../common/constantes';
import { GestionProyecto } from '../model/gestionProyecto';

const PATH = `${URL_HOME}/gestion`;

@Injectable({
  providedIn: 'root'
})
export class ProyectoGestionServiceService extends CRUDServiceService<GestionProyecto> {
  
  private _anio = new BehaviorSubject<number | null>(null);
  private _parametrosImputacion = new BehaviorSubject<any | null>(null);
  private _resumenGestion = new BehaviorSubject<any | null>(null);

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  set resumenGestion(resumen: any) {
    this._resumenGestion.next(resumen);
  }

  get resumenGestion(): Observable<any | null> {
    return this._resumenGestion.asObservable();
  }

  setAnio(anio: number) {
    this._anio.next(anio);
  }

  get getAnio(): Observable<number | null> {
    return this._anio.asObservable();
  }

  setParametrosImputacion(parametrosImputacion: any) {
    this._parametrosImputacion.next(parametrosImputacion);
  }

  get getParametrosImputacion(): Observable<any | null> {
    return this._parametrosImputacion.asObservable();
  }

  obtenerFechaPlanificadas(anio: number, idProyecto: number) : Observable<Usuario[]>{
      return this.http.post<Usuario[]>(`${this.prefix}/obtenerFechaPlanificadas/` + idProyecto, anio).pipe(take(1));
  }

  obtenerFechaImputadas(anio: number, idProyecto: number) : Observable<Usuario[]>{
    return this.http.post<Usuario[]>(`${this.prefix}/obtenerFechaImputadas/` + idProyecto, anio).pipe(take(1));
}

  obtenerCosteFechasPlanificadas(anio: number, idProyecto: number) : Observable<Usuario[]>{
    return this.http.post<Usuario[]>(`${this.prefix}/obtenerCosteFechasPlanificadas/` + idProyecto, anio).pipe(take(1));
  }
 
  guardarPlanificacion(planificacion: Usuario[] | null, idProyecto: number) {
    return this.http.post<Usuario[]>(`${this.prefix}/guardarPlanificacion/` + idProyecto, planificacion).pipe(take(1));
  }

  obtenerCosteFechasImputadas(anio: number, idProyecto: number) : Observable<Usuario[]>{
    return this.http.post<Usuario[]>(`${this.prefix}/obtenerCosteFechasImputadas/` + idProyecto, anio).pipe(take(1));
  }

  cierreParcial(idProyecto: number, fecha: Date): Observable<number> {
    return this.http.post<number>(`${this.prefix}/cierreParcial/${idProyecto}`, fecha).pipe(take(1));
  }

  imputacionesSinEntregar(idProyecto: number, fecha: Date): Observable<boolean> {
    return this.http.post<boolean>(`${this.prefix}/imputacionesSinEntregar/${idProyecto}`, fecha).pipe(take(1));
  }
}
